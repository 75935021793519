import React from "react"
import h2p from "html2plaintext";
import Link from "gatsby-link";
import { graphql } from 'gatsby';
// import Commento from "../components/commento"
import TemplateWrapper from "../components/template-wrapper"
import buildCategoryPath from "../utils/category-path.js"

const BlogPostTemplate = ({ data: { previous, next, allFile, markdownRemark: post }, pageContext }) => {
    console.log([pageContext.id]);
    console.log(post.frontmatter.title);
    const headerImages = allFile.nodes;
//    const siteTitle = props.data.site.siteMetadata.title
    const { category, link } = pageContext;
    var accumulation = h2p(post.html);
    const textExcerpt = h2p(post.excerpt);
    let palette = "palette--cst-site";

    const tagsString = post.frontmatter.tags;
    // const tagsString = (
    //   post.frontmatter.tags && post.frontmatter.tags.reduce( ( accum, tag ) => {
    //     if (accum === undefined)
    //       accum = '';
    //     if (tag === undefined) 
    //       return accum;

    //     return accum += ( counter++ > 0 ? `, ` : '' ) + tag.name;
    //   }, "")
    // )
    accumulation += " " + tagsString + " " + h2p(post.frontmatter.title);

    const share = {
      url: 'https://blogs.cybersym.com' + link,
      title: h2p(post.frontmatter.title),
      excerpt: textExcerpt,
      tagsString: (tagsString) ? tagsString : '',
      realContent: true
    };
      
    switch ( category ) {
      case "ultrarunning": 
        palette = "palette--ultra-site";
        break;
      case "tech-intersection": 
        palette = "palette--tech-site";
        break;
      case "science-society": 
        palette = "palette--cst-site";
        break;
      default:  
    }

  return (
    <div className={palette} style={{ textAlign: "center" }}> 
      <TemplateWrapper headerImages={headerImages} accumulation = {accumulation} category={category} share={share} >    
        <div className="blog-post" key={post.id}>
          <h2>
            <Link to={buildCategoryPath(category, post.fields.link)} dangerouslySetInnerHTML={{__html: post.frontmatter.title}}/>
          </h2>
          <h4>Written by {post.frontmatter.author} on {post.frontmatter.date}</h4>
          <h4>Tags: {tagsString}  
          {/* {
            post.frontmatter.tags && 
            post.frontmatter.tags
            .map( tag => (
              <span key={tag.id}>
                { counter++ > 0 ? `, ` : ' '}
                {tag.name}
              </span>
          ))} */}
          </h4>  
          <div className="blog-post-inner"
            dangerouslySetInnerHTML={{__html: post.html}} 
            style={{
              display: "inline",
              padding: 0,
              // paddingTop: "0.5rem",
              // paddingBottom: "0.5rem",
            }}
          >
          </div>  
          {/* <Commento id = {post.frontmatter.title} linkId={link} />   */}
          <ul
            style={{
              listStyle: `none`,
              padding: 0,
              paddingTop: "1.0rem",
            }}
          >
            <li>
              {previous && (
                <Link to={buildCategoryPath(category, previous.fields.link)} rel="prev" dangerouslySetInnerHTML={{__html: "Previous: " + previous.frontmatter.title }}/>
              )}
            </li>
            <li>
              {next && (
                <Link to={buildCategoryPath(category, next.fields.link)} rel="next" dangerouslySetInnerHTML={{__html: "Next: " + next.frontmatter.title }}/>
              )}
            </li>
         </ul>
        </div>
      </TemplateWrapper>
    </div>
  );  
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query(
    $id: String!
    $regex: String!
    $previousPostId: String
    $nextPostId: String
    ) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt
      fields {
        link
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        author
        title
        tags
      }
    }    
    allFile(filter: { sourceInstanceName: { eq: "images" }, 
    name: { regex: $regex } }) 
    {       
      nodes {
        id
        relativePath
        extension
        name
        childImageSharp {
          gatsbyImageData( 
            layout: CONSTRAINED,
            width: 2400, 
            placeholder: BLURRED
          )  
        }
      } 
    } 
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        link
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        link
      }
      frontmatter {
        title
      }
    }
  }
`


